<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="8">
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Type <span class="text-danger mr5">*</span></label>
                      <v-select v-model="row.ms_type" placeholder="Select Type" :options="[{label: 'Partner', value: 'P'}, {label: 'Staff', value: 'S'}]" @input="setPositionOptions(row.ms_type)" :reduce="v => v.value">
                      </v-select>
                      <VValidate name="Type" v-model="row.ms_type" :rules="mrValidation.ms_type" />
                    </b-form-group>
                  </b-col>   
                  <b-col lg="6">
                    <b-form-group>                      
                      <label>Position <span class="text-danger mr5">*</span></label>
                      <v-select v-model="row.ms_position" placeholder="Select Position" :options="!row.ms_type ? [] : positionOptions" :reduce="v => v.value" :disabled="emptyType">
                      </v-select>
                      <VValidate name="Position" v-model="row.ms_position" :rules="mrValidation.ms_position" />
                    </b-form-group>
                  </b-col> 

                  <b-col lg="12" v-if="row.ms_type == 'P' && $parent.isAdd">
                    <b-form-group>
                      <b-form-checkbox v-model="row.ms_is_existing_partner">Create From Partner Data</b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <template v-if="$parent.isAdd">
                        <label>Surveyor Name <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.ms_name" placeholder="e.g Surveyor Name" :disabled="emptyType" v-if="!row.ms_is_existing_partner">
                        </b-form-input>
                        <VValidate name="Surveyor Name" v-model="row.ms_name" :rules="mrValidation.ms_name" v-if="!row.ms_is_existing_partner" />

                        <v-select v-model="row.ms_partner_id" placeholder="Select Partner" :options="mrPartner" :reduce="v => v.value" :disabled="emptyType" v-else @input="setExistClient()"></v-select>
                        <VValidate name="Surveyor Name" v-model="row.ms_partner_id" :rules="{required: 1}" v-if="row.ms_is_existing_partner" />
                      </template>

                      <template v-else>
                        <label>Surveyor Name <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.ms_name" placeholder="e.g Surveyor Name" :disabled="emptyType">
                        </b-form-input>
                        <VValidate name="Surveyor Name" v-model="row.ms_name" :rules="mrValidation.ms_name" />
                      </template>                      
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>NIK <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.ms_nik" placeholder="e.g 3201010203..." type="number" :formatter="$parent.nik" :disabled="emptyType">
                      </b-form-input>
                      <VValidate name="NIK" v-model="row.ms_nik" :rules="mrValidation.ms_nik" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Gender <span class="text-danger mr5">*</span></label>
                      <v-select v-model="row.ms_gender" placeholder="Select Gender" :options="[{label: 'Man', value: 'M'}, {label: 'Woman', value: 'W'}]" :reduce="v => v.value" :disabled="emptyType">
                      </v-select>
                      <VValidate name="Gender" v-model="row.ms_gender" :rules="mrValidation.ms_gender" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="joinDate">Join Date</label>
                      <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="joinDate" prefix-class="crm" v-model="row.ms_join_date" placeholder="Join Date" :disabled-date="$parent.notAfterToday" :disabled="emptyType"></date-picker>                      
                    </b-form-group>
                  </b-col>                                                 
                  <b-col lg="6">
                    <b-form-group>
                      <label>Business Name <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.ms_business_name" placeholder="e.g ..." :disabled="emptyType">
                      </b-form-input>
                      <VValidate name="Business Name" v-model="row.ms_business_name" :rules="mrValidation.ms_business_name" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Mobile Number  <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.ms_phone" type="number" :formatter="$parent.telp" placeholder="e.g Mobile Number" :disabled="emptyType"></b-form-input>
                      <VValidate name="Mobile Number" v-model="row.ms_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group class="">
                      <label>Status<span class="text-danger mr5">*</span></label>
                      <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                      <VValidate name="Status" v-model="row.ms_address" :rules="mrValidation.ms_status" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col lg="4" id="my-node">
                <b-col lg="12">
                  <div class="file-uploader">
                    <label>Photo <span class="text-danger">*</span></label>
                    <Uploader v-model="row.ms_avatar" type="surveyor"/>
                    <VValidate name="Photo" v-model="row.ms_avatar" :rules="{required: 1}" />
                  </div>
                </b-col>

                <b-col lg="12 mt-3" v-if="$parent.pageSlug != 'add' && row.ms_qr_code">
                  <div class="file-uploader">
                    <label>QR Code <span class="text-danger">*</span></label>                    

                    <div class="d-flex flex-column align-items-center">
                      <img :src="$parent.uploader(row.ms_qr_code)" alt="QR Code" class="img_responsive w-50" v-if="row.ms_qr_code && !isLoadingGenerate">
                      <div class="text-center text-danger my-2" v-if="isLoadingGenerate">
                        <b-spinner class="align-middle mr-3 text-info"></b-spinner>                       
                      </div>

                      <div class="buttons w-100 d-flex justify-content-center">
                        <b-button variant="info" @click="downloadQR()"><i class="ti-download"></i> Download QR</b-button>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>            
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
      mrValidation: Object,
      mrPosition: [Array, Object],
      mrPartner: [Array, Object],
      Config: Object,
      isLoadingGenerate: Boolean
    },
    data(){
      return {
        positionOptions: [],
        disabledGenerate: false
      }
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
      setPositionOptions(type){
        this.$set(this.row, 'ms_position', null)
        this.$set(this, 'positionOptions', this.mrPosition.filter(x => x.type == type ))
      },
      generateQR(){        
        this.$emit('click:generateQrCode')
      },
      downloadQR(){
        this.$emit('click:download', `uploader/${this.row.ms_qr_code}`)
      },
      setExistClient(){
        const partnerID = this.row.ms_partner_id
        const selectedClient = this.mrPartner.find(x => x.value == partnerID)

        this.$set(this.row, 'ms_nik', selectedClient.nik)
        this.$set(this.row, 'ms_phone', selectedClient.phone)
        this.$set(this.row, 'ms_avatar', selectedClient.avatar)

        console.log(this.row)
      }
    },
    mounted(){
      setTimeout(() => {         
        if(this.row.ms_type) this.$set(this, 'positionOptions', this.mrPosition.filter(x => x.type == this.row.ms_type ))

      }, 700)
    },
    computed:{ 
      emptyType(){
        return !this.row.ms_type
      }
    }
 }
</script>
